import React from 'react';
import { Link } from 'gatsby';
import { Button } from '../elements/buttons/Button';
import { useResources } from '~/hooks/useResources';
import DateFormat from '~/components/elements/date';
import Image from '../elements/Image';

export function LatestNews({ heading, link }) {
  const posts = useResources();

  const blog = posts
    ?.filter((p) => p?.categories?.nodes.filter((c) => c.name === 'Blog').length > 0)
    .sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());
  const press = posts
    ?.filter((p) => p?.categories?.nodes.filter((c) => c.name === 'Press Releases').length > 0)
    .sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());

  

  return (
    <section className="container !max-w-[1230px] pt-3 md:pt-0">
      <div className="flex flex-col md:flex-row flex-wrap items-center justify-center text-center md:text-left md:justify-between -ml-6 mb-6 sm:mb-8 md:mb-16">
        <h2 className="text-h2 pl-6 pb-3" dangerouslySetInnerHTML={{ __html: heading }} />
        <div className="pl-6 pb-3">
          <Button type="link" link={link} />
        </div>
      </div>

      <div className="grid md:grid-cols-2 gap-x-16 lg:gap-x-28 gap-y-12">
        {blog?.length > 0 ? (
          <div>
            <h4 className="mb-8 t-24 !font-body">Blog Posts</h4>
            {/* Post */}
            <Post post={blog[0]} />
          </div>
        ) : null}

        {press?.length > 0 ? (
          <div>
            <h4 className="mb-8 t-24 !font-body">Press Releases</h4>
            <div className="space-y-10">
              <Post type="small" post={press[0]} />
              <Post type="small" post={press[1]} />
            </div>
          </div>
        ) : null}
      </div>
    </section>
  );
}

export function Post({ type = 'large', post }) {
  const image = post?.postTypePost?.summary?.image;
  console.log('press is: '+post);
 
  const customSlugs = {
    "/how-pathway-homes-gained-efficiency-and-strengthened-lender-relationships-with-setpoint/": "/case-study/pathway-homes/",
    "/how-setpoint-empowers-backflip-to-scale-with-lean-operations-2/": "/case-study/casestudytestpage/"
    // "/third-old-slug/": "/new-replacement-link-3/"
  };
  
  return (
    
    <Link
    to={customSlugs[post?.uri] || post?.uri} // Replace if found, else use original
    className={`group block ${type === 'small' ? 'md:flex md:space-x-5' : ''}`}>
      <div
        className={`${
          type === 'small' ? 'w-full md:flex-[0_0_33%] md:aspect-square' : 'w-full'
        } aspect-[486/360] rounded-[10px] overflow-hidden bg-grey`}
      >
        <Image
          objectFit={'cover'}
          image={image}
          className="w-full h-full object-cover group-hover:opacity-80 duration-200 transition-opacity"
        />
      </div>
      <div className={`pt-5 ${type !== 'small' ? '' : 'md:py-2'} space-y-3 flex flex-col items-start`}>
        <div className={`mr-4 flex-auto`}>
          <div
            className={`text-dark t-22 ${type === 'small' ? '' : 'md:t-24'} leading-snug line-clamp-3`}
            dangerouslySetInnerHTML={{ __html: post?.title }}
          />
          <div className="t-14 text-dark text-opacity-60 mt-1.5">
            <DateFormat dateString={post.date} />
          </div>
        </div>
        <div className="text-dark font-bold relative inline-block pt-1 hover:opacity-90 transition-opacity duration-200">
          Read more
          <div className="absolute inset-x-0 bottom-[-2px] h-[2px] w-full bg-orange"></div>
        </div>
      </div>
    </Link>
  );
}
